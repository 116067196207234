// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advance-nursing-care-jsx": () => import("./../src/pages/advance-nursing-care.jsx" /* webpackChunkName: "component---src-pages-advance-nursing-care-jsx" */),
  "component---src-pages-baby-care-jsx": () => import("./../src/pages/baby-care.jsx" /* webpackChunkName: "component---src-pages-baby-care-jsx" */),
  "component---src-pages-doctor-e-consultation-jsx": () => import("./../src/pages/doctor-e-consultation.jsx" /* webpackChunkName: "component---src-pages-doctor-e-consultation-jsx" */),
  "component---src-pages-elderly-care-jsx": () => import("./../src/pages/elderly-care.jsx" /* webpackChunkName: "component---src-pages-elderly-care-jsx" */),
  "component---src-pages-health-check-up-on-site-jsx": () => import("./../src/pages/health-check-up-on-site.jsx" /* webpackChunkName: "component---src-pages-health-check-up-on-site-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medical-equipments-jsx": () => import("./../src/pages/medical-equipments.jsx" /* webpackChunkName: "component---src-pages-medical-equipments-jsx" */),
  "component---src-pages-nurses-for-ohc-and-health-centre-jsx": () => import("./../src/pages/nurses-for-ohc-and-health-centre.jsx" /* webpackChunkName: "component---src-pages-nurses-for-ohc-and-health-centre-jsx" */),
  "component---src-pages-other-services-jsx": () => import("./../src/pages/other-services.jsx" /* webpackChunkName: "component---src-pages-other-services-jsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-physiotherapy-jsx": () => import("./../src/pages/physiotherapy.jsx" /* webpackChunkName: "component---src-pages-physiotherapy-jsx" */),
  "component---src-pages-post-operative-care-jsx": () => import("./../src/pages/post-operative-care.jsx" /* webpackChunkName: "component---src-pages-post-operative-care-jsx" */),
  "component---src-pages-pre-policy-health-check-up-jsx": () => import("./../src/pages/pre-policy-health-check-up.jsx" /* webpackChunkName: "component---src-pages-pre-policy-health-check-up-jsx" */),
  "component---src-pages-routine-bed-side-care-jsx": () => import("./../src/pages/routine-bed-side-care.jsx" /* webpackChunkName: "component---src-pages-routine-bed-side-care-jsx" */),
  "component---src-pages-transactional-care-jsx": () => import("./../src/pages/transactional-care.jsx" /* webpackChunkName: "component---src-pages-transactional-care-jsx" */),
  "component---src-pages-vaccination-jsx": () => import("./../src/pages/vaccination.jsx" /* webpackChunkName: "component---src-pages-vaccination-jsx" */)
}

